<template>
  <div id="areaSelect" class="is-comp">
    <input
      type="text"
      class="inp-select w300"
      readonly
      @click.stop="showOptions"
      :value="showData"
      :class="{ error: tip !== '' }"
    />
    <i class="ss-icon ss-unfold" @click.stop="showOptions"></i>
    <div class="tip">{{ tip }}</div>
    <div class="area-select-options" :style="{ width: boardWidth + 'px' }">
      <div class="w80" v-if="countryShow">
        <ul>
          <li
            v-for="(country, index) in countryData"
            @click.stop="showProvince(country.id, index)"
            :class="{ active: checkedData.countryId === country.id }"
            :key="index"
          >
            <span>{{ country.name }}</span>
            <i v-if="country.haveChild" class="ss-icon ss-enter"></i>
          </li>
        </ul>
      </div>
      <div class="w140" v-if="provinceShow">
        <ul>
          <li
            v-for="(province, index) in provinceData"
            @click.stop="showCity(province.id, index)"
            :class="{ active: checkedData.provinceId === province.id }"
            :key="index"
          >
            <span>{{ province.name }}</span>
            <i v-if="province.haveChild" class="ss-icon ss-enter"></i>
          </li>
        </ul>
      </div>
      <div class="w110" v-if="cityShow">
        <ul>
          <li
            v-for="(city, index) in cityData"
            @click.stop="showArea(city.id, index)"
            :class="{ active: checkedData.cityId === city.id }"
            :key="index"
          >
            <span>{{ city.name }}</span>
            <i v-if="city.haveChild" class="ss-icon ss-enter"></i>
          </li>
        </ul>
      </div>
      <div class="w120" v-if="areaShow">
        <ul>
          <li
            v-for="(area, index) in areaData"
            @click.stop="clickArea(area.id, index)"
            :class="{ active: checkedData.areaId === area.id }"
            :key="index"
          >
            <span>{{ area.name }}</span>
            <i v-if="area.haveChild" class="ss-icon ss-enter"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/areaSelect
 * @desc 速商 地区多级联动搜索
 * @version 0.0.2
 * @author wurongjian <wurongjian@rongyi.com>
 * @date 2021-08-03
 * @copyright 2021
 */
import { apiPost } from "../modules/utils";
import { event } from "../modules/EVENTs";
import * as CONST from "../modules/CONSTs";
import { mapGetters } from "vuex";
import APIs from "../modules/APIs";

const CODE_CHINA = CONST.AREA_CODE_CHAIN;
const NAME_CHINA = CONST.AREA_NAME_CHAIN;
const LEVEL_CITY = 2;
export default {
  name: "areaSelect",
  props: {
    countryId: {
      type: [String, Number],
      default: CODE_CHINA,
    },
    countryName: {
      type: String,
      default: NAME_CHINA,
    },
    provinceId: {
      type: [String, Number],
      default: "",
    },
    provinceName: {
      type: String,
      default: "",
    },
    cityId: {
      type: [String, Number],
      default: "",
    },
    cityName: {
      type: String,
      default: "",
    },
    areaId: {
      type: [String, Number],
      default: "",
    },
    areaName: {
      type: String,
      default: "",
    },
    tip: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 3,
    },
    index: {
      type: [String, Number],
      default: -1,
    },
    callback: {},
    initData: {
      type: [Object, Function, null],
      default: () => {},
    },
  },
  data() {
    return {
      boardWidth: 0,
      countryShow: false,
      provinceShow: false,
      cityShow: false,
      areaShow: false,
      checkedData: {
        countryId: CODE_CHINA,
        countryName: NAME_CHINA,
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      },
      countryData: [],
      provinceData: [],
      cityData: [],
      areaData: [],
    };
  },
  watch: {
    provinceShow(newValue) {
      if (newValue) {
        this.boardWidth += 140;
      } else {
        this.boardWidth -= 140;
      }
    },
    cityShow(newValue) {
      if (newValue) {
        this.boardWidth += 110;
      } else {
        this.boardWidth -= 110;
      }
    },
    areaShow(newValue) {
      if (newValue) {
        this.boardWidth += 120;
      } else {
        this.boardWidth -= 120;
      }
    },
    refresh(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        if (this.countryId !== "" && this.countryName !== "") {
          this.checkedData.countryId = this.countryId;
          this.checkedData.countryName = this.countryName;
        } else {
          this.checkedData.countryId = CODE_CHINA;
          this.checkedData.countryName = NAME_CHINA;
        }
        if (this.provinceId !== "" && this.provinceName !== "") {
          this.checkedData.provinceId = this.provinceId;
          this.checkedData.provinceName = this.provinceName;
        } else {
          this.checkedData.provinceId = "";
          this.checkedData.provinceName = "";
        }
        if (this.cityId !== "" && this.cityName !== "") {
          this.checkedData.cityId = this.cityId;
          this.checkedData.cityName = this.cityName;
        } else {
          this.checkedData.cityId = "";
          this.checkedData.cityName = "";
        }
        if (this.areaId !== "" && this.areaName !== "") {
          this.checkedData.areaId = this.areaId;
          this.checkedData.areaName = this.areaName;
        } else {
          this.checkedData.areaId = "";
          this.checkedData.areaName = "";
        }
      }
    },
    initData(nv) {
      this.checkedData = nv;
    },
  },
  computed: {
    ...mapGetters(["APIs"]),
    showData() {
      let province = "";
      if (this.checkedData.provinceName) {
        province = this.checkedData.provinceName;
      }
      let city = "";
      if (this.checkedData.cityId !== 0 && this.checkedData.cityName) {
        city = "／" + this.checkedData.cityName;
      }
      let area = "";
      if (this.checkedData.areaName) {
        area = "／" + this.checkedData.areaName;
      }
      return province + city + area;
    },
  },
  methods: {
    windowClick() {
      if (this.provinceShow || this.cityShow || this.areaShow) {
        this.closeOptions();
      }
    },
    /**
     * 关闭选择地区面板
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     */
    closeOptions() {
      this.provinceShow = false;
      this.cityShow = false;
      this.areaShow = false;
      window.removeEventListener("click", this.windowClick);
      this.emitData();
    },
    emitData() {
      if (typeof this.callback === "undefined") {
        this.$emit("setArea", this.checkedData);
        event.$emit("ss-area-select", this.checkedData);
      } else {
        this.callback(this.checkedData);
      }
    },
    /**
     * 开启选择地区面板
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     */
    showOptions() {
      let $this = this;
      if (this.provinceShow || this.cityShow || this.areaShow) {
        this.closeOptions();
      } else {
        let getProvinceData = new Promise(function(resolve) {
          if ($this.countryId !== "") {
            apiPost({
              url: APIs.COMPANY,
              data: {
                action: "regions.list",
                // region_id: $this.countryId
              },
              success(res) {
                if (res.code === "0") {
                  resolve(
                    res.data.map((el) => {
                      el.haveChild = el.level !== $this.level;
                      return el;
                    })
                  );
                } else {
                  resolve([]);
                }
              },
              error() {
                resolve([]);
              },
            });
          } else {
            resolve([]);
          }
        });
        let getCityData = new Promise(function(resolve) {
          if ($this.provinceId !== "") {
            apiPost({
              url: APIs.COMPANY,
              data: {
                action: "regions.list",
                region_id: $this.provinceId,
              },
              success(res) {
                if (res.code === "0") {
                  let list = res.data.map((el) => {
                    el.haveChild = el.level !== $this.level;
                    return el;
                  });
                  if ($this.level === LEVEL_CITY) {
                    list.unshift({
                      id: 0,
                      name: "全省",
                      level: 2,
                      haveChild: false,
                      pid: $this.provinceId,
                    });
                  }
                  resolve(list);
                } else {
                  resolve([]);
                }
              },
              error() {
                resolve([]);
              },
            });
          } else {
            resolve([]);
          }
        });
        let getAreaData = new Promise(function(resolve) {
          if ($this.cityId !== "") {
            apiPost({
              url: APIs.COMPANY,
              data: {
                action: "regions.list",
                region_id: $this.cityId,
              },
              success(res) {
                if (res.code === "0") {
                  resolve(
                    res.data.map((el) => {
                      el.haveChild = el.level !== $this.level;
                      return el;
                    })
                  );
                } else {
                  resolve([]);
                }
              },
              error() {
                resolve([]);
              },
            });
          } else {
            resolve([]);
          }
        });
        Promise.all([getProvinceData, getCityData, getAreaData])
          .then((values) => {
            $this.provinceData = values[0];
            $this.cityData = values[1];
            $this.areaData = values[2];
            window.addEventListener("click", this.windowClick);
            this.provinceShow = true;
            if (this.checkedData.provinceId !== "") {
              this.provinceShow = true;
            }
            if (this.checkedData.cityId !== "") {
              this.cityShow = true;
            }
            if (this.checkedData.areaId !== "") {
              this.areaShow = true;
            }
          })
          .catch(() => {});
      }
    },
    /**
     * 展开省份
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     * @param  {int}   countryId 国家id
     * @param  {int}   countryIndex 国家的索引
     */
    showProvince(countryId, countryIndex) {
      let checkCountry = this.countryData[countryIndex];
      this.checkedData.countryId = checkCountry.id;
      this.checkedData.countryName = checkCountry.name;
      this.cityShow = false;
      this.areaShow = false;
      this.checkedData.provinceId = "";
      this.checkedData.provinceName = "";
      this.checkedData.cityId = "";
      this.checkedData.cityName = "";
      this.checkedData.areaId = "";
      this.checkedData.areaName = "";
      if (!checkCountry.haveChild) {
        this.closeOptions();
        this.checkedData.provinceId = "";
        this.checkedData.provinceName = "";
        this.checkedData.cityId = "";
        this.checkedData.cityName = "";
        this.checkedData.areaId = "";
        this.checkedData.areaName = "";
      } else {
        this.provinceShow = true;
        let $this = this;
        apiPost({
          url: this.APIs.COMPANY,
          data: {
            action: "regions.list",
            region_id: countryId,
          },
          success(res) {
            if (res.code === "0") {
              $this.provinceData = res.data.map((el) => {
                el.haveChild = el.level !== $this.level;
                return el;
              });
            } else {
              $this.provinceData = [];
            }
          },
          error() {
            $this.provinceData = [];
          },
        });
      }
    },
    /**
     * 展开市
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     * @param  {int}   provinceId    省份id
     * @param  {int}   provinceIndex 省份的索引
     */
    showCity(provinceId, provinceIndex) {
      let checkProvince = this.provinceData[provinceIndex];
      this.checkedData.provinceId = checkProvince.id;
      this.checkedData.provinceName = checkProvince.name;
      this.areaShow = false;
      this.checkedData.cityId = "";
      this.checkedData.cityName = "";
      this.checkedData.areaId = "";
      this.checkedData.areaName = "";
      if (!checkProvince.haveChild) {
        this.closeOptions();
        this.checkedData.cityId = "";
        this.checkedData.cityName = "";
        this.checkedData.areaId = "";
        this.checkedData.areaName = "";
      } else {
        this.cityShow = true;
        let $this = this;
        apiPost({
          url: APIs.COMPANY,
          data: {
            action: "regions.list",
            region_id: provinceId,
          },
          success(res) {
            if (res.code === "0") {
              let list = res.data.map((el) => {
                el.haveChild = el.level !== $this.level;
                return el;
              });
              if ($this.level === LEVEL_CITY) {
                list.unshift({
                  id: 0,
                  name: "全省",
                  level: 2,
                  haveChild: false,
                  pid: provinceId,
                });
              }
              $this.cityData = list;
            } else {
              $this.cityData = [];
            }
          },
          error() {
            $this.cityData = [];
          },
        });
      }
    },
    /**
     * 展开区域
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     * @param  {int}   cityId    市id
     * @param  {int}   cityIndex 市的索引
     */
    showArea(cityId, cityIndex) {
      let checkCity = this.cityData[cityIndex];
      this.checkedData.cityId = checkCity.id;
      this.checkedData.cityName = checkCity.name;
      this.checkedData.areaId = "";
      this.checkedData.areaName = "";
      if (!checkCity.haveChild || cityId === 0) {
        this.closeOptions();
        this.checkedData.areaId = "";
        this.checkedData.areaName = "";
      } else {
        this.areaShow = true;
        let $this = this;
        apiPost({
          url: APIs.COMPANY,
          data: {
            action: "regions.list",
            region_id: cityId,
          },
          success(res) {
            if (res.code === "0") {
              $this.areaData = res.data.map((el) => {
                el.haveChild = el.level !== $this.level;
                return el;
              });
            } else {
              $this.areaData = [];
            }
          },
          error() {
            $this.areaData = [];
          },
        });
      }
    },
    /**
     * 点击选择区域
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-08-03
     * @param  {int}   areaId    区域id
     * @param  {int}   areaIndex 区域的索引
     */
    clickArea(areaId, areaIndex) {
      let checkArea = this.areaData[areaIndex];
      this.checkedData.areaId = checkArea.id;
      this.checkedData.areaName = checkArea.name;
      if (this.index !== -1) {
        this.checkedData.serialNumber = this.index;
      }
      this.closeOptions();
    },
  },
  mounted() {},
};
</script>

<style lang="less">
@import (reference) "../less/common";
#areaSelect {
  .pos-r();
  .fs(0);
  .tip {
    .fc(12px, #f84445);
    .pos-a(1, 23px, 0);
  }
  > .area-select-options {
    .pos-a(1, 30px, 0);
    .bgc(#ffffff);
    box-shadow: 0 4px 12px 0 rgba(83, 92, 105, 0.12);
    .brdr(4px);
    > div {
      .fc(12px, #525e6e);
      .dib();
      .va-t();
      overflow-y: auto;
      height: 160px;
      .brd-r(1px, solid, #eceff3);
      &:last-child {
        .no-brd-r();
      }
      > ul {
        list-style: none;
        .pd(0);
        .mg(0);
        > li {
          position: relative;
          height: 30px;
          line-height: 30px;
          .pdl(8px);
          .pdr(3px);
          .cs();
          &:hover,
          &.active {
            .bgc(#e9f2f1);
          }
          > i {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
    span {
      padding-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
}
</style>
