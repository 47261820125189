<template>
  <div class="wrapper channel-main" ref="page">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />
    <div class="content">
      <div class="table-head">
        <div class="left">
          <input
            type="text"
            class="search"
            placeholder="渠道商名称搜索"
            @keypress.enter="getList()"
            v-model="tableSet.name"
          />
          <i class="sdnm-icon sdnm-search" @click="getList()"></i>
          <input
            type="text"
            class="search"
            placeholder="联系人搜索"
            @keypress.enter="getList()"
            v-model="tableSet.contact_name"
          />
          <i class="sdnm-icon sdnm-search" @click="getList()"></i>
        </div>
        <div class="right">
          <button class="btn primary ver-ali-top" @click="add">新建</button>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th v-for="field in heads" :class="field.class" :key="field.id">
                {{ field.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataList" :key="row.id">
              <td class="pw10 ellipsis" :title="row.channel_num">
                {{ row.channel_num }}
              </td>
              <td class="pw10 ellipsis" :title="row.name">{{ row.name }}</td>
              <td class="pw10 ellipsis" :title="row.contact_name">
                {{ row.contact_name }}
              </td>
              <td class="pw10 ellipsis" :title="row.contact_phone">
                {{ row.contact_phone }}
              </td>
              <td class="pw15 ellipsis">
                {{ row.province_name }}/{{ row.city_name }}/{{
                  row.district_name
                }}
              </td>
              <td class="pw20 ellipsis" :title="row.address">
                {{ row.address }}
              </td>
              <td class="pw13 ellipsis" :title="row.remark">
                {{ row.remark }}
              </td>
              <td class="operation pw12">
                <a href="javascript:void(0)" @click="edit(row)">编辑</a>
                <a href="javascript:void(0)" @click="del(row.id)">删除</a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" :colspan="heads.length">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="tableSet.start"
        :total="tableSet.total"
        :page-length="tableSet.length"
        :pageCallback="getList"
      ></pagination>
    </div>
    <layers
      :show="isShow"
      :title="layerTitle"
      :width="580"
      :btnLoadDisabled="disabled"
      :saveCallBack="save"
      :closeCallBack="cancel"
    >
      <div class="form">
        <div class="field">
          <label class="w120 tar">渠道商名称</label>
          <input
            type="text"
            class="w300"
            v-model="layerData.name"
            placeholder=""
            maxlength="20"
          />
        </div>
        <div class="field">
          <label class="w120 tar">联系人</label>
          <input
            type="text"
            class="w300"
            v-model="layerData.contact_name"
            placeholder=""
            maxlength="20"
          />
        </div>
        <div class="field">
          <label class="w120 tar">联系电话</label>
          <input
            type="tel"
            class="w300"
            v-model="layerData.contact_phone"
            placeholder=""
            maxlength="11"
            @input="checkInput"
          />
        </div>
        <div class="field">
          <label class="w120 tar">地区</label>
          <area-select
            :initData="areaData"
            :level="3"
            @setArea="getCity"
            position="top"
          ></area-select>
        </div>
        <div class="field">
          <label class="w120 tar">详细地址</label>
          <textarea class="w300" v-model="layerData.address"></textarea>
        </div>
        <div class="field">
          <label class="w120 tar">备注</label>
          <textarea class="w300" v-model="layerData.remark"></textarea>
        </div>
      </div>
    </layers>
  </div>
</template>

<script>
/**
 * @file pages/index.vue
 * @desc this is index.vue
 * @version 0.1.0
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
// import router from '@/router';
// import * as URL from '_/URLs';
import APIs from "../../modules/APIs";
import { apiPost } from "../../modules/utils";
// import ctheader from "@/ctheader";
// import layers from "@/layers";
// import pagination from "@/pagination";
// import areaSelect from "@/areaSelect";
import ctheader from "../../components/ctheader";
import layers from "../../components/layers";
import pagination from "../../components/pagination";
import areaSelect from "../../components/areaSelect";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

const FIELDS = [
  { name: "渠道商编号", class: "pw10" },
  { name: "渠道商名称", class: "pw10" },
  { name: "联系人", class: "pw10" },
  { name: "联系电话", class: "pw10" },
  { name: "地区", class: "pw15" },
  { name: "详细地址", class: "pw20" },
  { name: "备注", class: "pw13" },
  { name: "操作", class: "pw12" },
];

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  data() {
    return {
      heads: FIELDS,
      dataList: [],
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      tableSet: {
        start: 0,
        length: 10,
        total: 0,
        name: "",
        contact_name: "",
      },
      isShow: false,
      disabled: false,
      layerData: {
        name: "",
        contact_name: "",
        contact_phone: "",
        district_id: "",
        address: "",
        remark: "",
        isEdit: false,
        editId: "",
      },
      areaData: {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      },
    };
  },
  components: { ctheader, layers, pagination, areaSelect },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
    });
  },
  computed: {
    layerTitle() {
      return this.layerData.isEdit ? "编辑渠道商" : "新增渠道商";
    },
    ...getters,
  },
  methods: {
    edit(row) {
      this.layerData.name = row.name;
      this.layerData.contact_name = row.contact_name;
      this.layerData.contact_phone = row.contact_phone;
      this.layerData.district_id = row.district_id;
      this.layerData.address = row.address;
      this.layerData.remark = row.remark;
      this.layerData.editId = row.id;
      this.layerData.isEdit = true;

      this.areaData = {
        provinceId: row.province_id,
        provinceName: row.province_name,
        cityId: row.city_id,
        cityName: row.city_name,
        areaId: row.district_id,
        areaName: row.district_name,
      };
      this.isShow = true;
    },
    del(id) {
      let _this = this;
      apiPost({
        url: APIs.CHANNEL,
        data: {
          id: id,
          action: "delete.channel",
        },
        success(res) {
          if (res.code === "0") {
            _this.getList();
          }
          _this.setAlert({ msg: res.msg, type: res.code === "0" });
        },
        // error(res) {},
      });
    },
    checkInput() {},
    getCity(val) {
      if (val.provinceName === "其他" || val.provinceName === "海外") {
        this.layerData.district_id = val.provinceId;
      } else {
        this.layerData.district_id = val.areaId;
      }
      this.areaData = val;
    },
    save() {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.layerData.contact_phone)) {
        this.setAlert({
          msg: "请输入正确的手机号",
        });
        return;
      } else {
        let data = {
          name: this.layerData.name,
          contact_name: this.layerData.contact_name,
          contact_phone: this.layerData.contact_phone,
          district_id: this.layerData.district_id,
          address: this.layerData.address,
          remark: this.layerData.remark,
        };
        if (this.layerData.isEdit) {
          data.action = "edit.channel";
          data.id = this.layerData.editId;
        } else {
          data.action = "save.channel";
        }

        let _this = this;
        apiPost({
          url: APIs.CHANNEL,
          data,
          success(res) {
            if (res.code === "0") {
              _this.getList();
              _this.isShow = false;
            }
            _this.setAlert({ msg: res.msg, type: res.code === "0" });
          },
          // error(res) {},
        });
      }
    },
    cancel() {
      this.isShow = false;
    },
    getList(index = 0) {
      let _this = this;
      this.tableSet.start = index;
      apiPost({
        url: APIs.CHANNEL,
        data: {
          action: "channel.list",
          start: this.tableSet.start,
          length: this.tableSet.length,
          name: this.tableSet.name,
          contact_name: this.tableSet.contact_name,
        },
        success(res) {
          if (res.code === "0") {
            _this.dataList = res.data;
            _this.tableSet.total = res.total;
          }
        },
        // error(res) {},
      });
    },
    add() {
      this.layerData.name = "";
      this.layerData.contact_name = "";
      this.layerData.contact_phone = "";
      this.layerData.district_id = "";
      this.layerData.address = "";
      this.layerData.remark = "";
      this.layerData.editId = "";
      this.layerData.isEdit = false;

      this.areaData = {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
      };
      this.isShow = true;
    },
    ...actions,
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
.channel-main {
  .form {
    .pdt(40px);
    padding-left: 20px;
    label {
      width: 120px;
    }
  }
  .field {
    .tar {
      text-align: right;
    }
    input {
      padding-right: 30px;
    }
  }
}
</style>
